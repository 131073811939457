body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-button {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  background-image: linear-gradient(94.05deg,#11998e 1.74%,#38ef7d);
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.disable-button {
  pointer-events: none;
  opacity: 0.5;
  color: white!important;
  border: 1px solid white!important;
}

.stat-bundle-text {
  line-height: 18px;
}